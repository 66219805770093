import React from "react";

import Layout from "../components/Layout";
import SideBar from "../components/SideBar";

const ErrorPage = () => (
  <Layout>
    <SideBar />
    <div>
      <div>
        <section>
          <div>
            <section>
              <h1>An error has occurred</h1>
              <p>Thanks for filling in the questionnaire, there was an error processing your request.</p>
              <p>We will contact you soon through e-mail with your results.</p>
            </section>
          </div>
        </section>
      </div>
    </div>
  </Layout>
);

export default ErrorPage;
